.main {
    padding: 0;
    max-height: 900px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: visible;
  }
  
  .gallery {
    /* z-index: 1; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    gap: 2rem;
  }
  @media (max-width: 1366px) {
    .main {
      max-height: 800px;
    }
  }
  @media (max-width: 1024px) {
    .main {
      max-height: 400px;
      overflow: hidden;
      overflow-y: hidden;
    }
  }
  
  .col {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    align-self: flex-start;
    justify-self: flex-start;
  }
  
  .col:nth-child(2) {
    align-self: flex-end;
    justify-self: flex-end;
    justify-content: flex-end;
  }
  
  .image {
    width: 100%;
    padding: 1rem;
  }
  
  
  .image img {
    transition: 0.3s ease-out;
    overflow: hidden;
    width: 100%;
    border-radius: 20px;
    box-shadow: rgba(0,0,0,.15) 3px 3px 20px;
  }